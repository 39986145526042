import React, { useEffect } from "react"

function NotFoundPage() {
  useEffect(() => {
    window.location.href = "/"
  }, [])

  return <main></main>
}

export default NotFoundPage
